import { useNavigate } from '@remix-run/react';
import { format } from 'date-fns';
import { type FormEvent, useRef, useState } from 'react';

import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { useAnonAppAnalytics } from '../../../../src/analytics/app/anon';
import { EMAIL_PATTERN } from '../../../../src/components/Access/types';
import { apiService } from '../../../../src/services/api-service';
import { hubspotTrackInstantQuoteFormSubmitted } from '../../../../src/tracking/hubspot';
import { type GamePack } from '../../../../src/types/game';
import { isGamePackLaunched } from '../utils';

function GetInstantQuote(props: { pack: GamePack }) {
  const { pack } = props;

  const analytics = useAnonAppAnalytics();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const emailRef = useRef<HTMLInputElement>(null);
  const [emailErr, setEmailErr] = useState<string | null>(null);
  const [headcount, setHeadcount] = useState<number | undefined>(undefined);
  const headcountRef = useRef<HTMLInputElement>(null);
  const [headcountErr, setHeadcountErr] = useState<string | null>(null);

  const handleHeadcountChange = (value: number | undefined) => {
    setHeadcountErr(null);
    setHeadcount(value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!headcount) {
      headcountRef.current?.focus();
      setHeadcountErr('Please enter your headcount');
      return;
    }
    if (!email) {
      emailRef.current?.focus();
      setEmailErr('Please enter your email');
      return;
    }
    if (!EMAIL_PATTERN.test(email)) {
      emailRef.current?.focus();
      setEmailErr('Please enter a valid email');
      return;
    }

    try {
      await apiService.auth.checkEmailAvailability({
        email,
      });
    } catch (error) {
      const apiErr = apiService.utils.CastAPIError(error);
      if (apiErr?.msg === 'free_email_address') {
        emailRef.current?.focus();
        setEmailErr('Please enter your work email address');
        return;
      }
    }

    analytics.trackLibraryGameDetailsClicked(
      pack.id,
      pack.name,
      'See Instant Quote'
    );

    hubspotTrackInstantQuoteFormSubmitted({
      email,
      headcount,
      kind: 'gamePack',
      gamePackName: pack.name,
    });

    const params = new URLSearchParams(window.location.search);
    params.set('pack-id', pack.id);
    params.set('email', email);
    params.set('headcount', headcount.toString());
    params.set('register-for', 'oneTimePurchase');
    navigate(`/register?${params.toString()}`);
  };

  return (
    <div className='relative w-full flex flex-col items-center gap-4'>
      <form
        id='get-instant-quote-form'
        onSubmit={handleSubmit}
        className='w-full bg-main-layer rounded-xl p-2 pt-7'
      >
        <p className='text-center text-xl font-bold text-tertiary'>
          {isGamePackLaunched(pack)
            ? 'Get An Instant Quote'
            : `Launching ${format(
                new Date(pack.detailSettings?.availability?.launchDate ?? ''),
                'M/d/yy'
              )}`}
        </p>

        <div className='mt-7 w-full flex flex-col gap-2.5'>
          <div className='flex flex-col items-center justify-center gap-2.5 px-2 pb-3'>
            {/* 
                This is a workaround to prevent the form from being collected by Hubspot.
                Refer to https://community.hubspot.com/t5/Lead-Capture-Tools/Ignore-a-specific-non-Hubspot-form-from-being-collected/m-p/246957
              */}
            <label htmlFor='cc-num' className='hidden'></label>
            <input
              name='cc-num'
              className='hidden'
              defaultValue='HubspotCollectedFormsWorkaround'
              id='cc-num'
            />

            <div className='w-full bg-layer-002 border border-secondary rounded-xl pl-4 p-3 flex items-center gap-2'>
              <div className='flex-1 flex flex-col gap-1 text-white'>
                <div className='font-bold'>Headcount</div>
                <div className='text-2xs font-light'>
                  You can Increase the team size later!
                </div>
              </div>

              <div className='flex-none w-34 flex flex-col items-end gap-1'>
                <input
                  name='headcount'
                  type='number'
                  value={headcount || ''}
                  onChange={(e) =>
                    handleHeadcountChange(e.currentTarget.valueAsNumber)
                  }
                  className={`
                     w-20 text-xl font-bold text-center m-0 p-0 transition-colors
                    ${headcountErr ? 'field-error' : 'field'}
                  `}
                  placeholder={'0'}
                  ref={headcountRef}
                />
                <div className='text-red-006 text-3xs h-3 whitespace-nowrap'>
                  {headcountErr}
                </div>
              </div>
            </div>

            <div className='w-full p-2 bg-layer-002 border border-secondary rounded-xl flex flex-col gap-1.5'>
              <div className='flex-1 text-white px-2'>
                <div className='font-bold'>Email</div>
              </div>

              <input
                ref={emailRef}
                name='email'
                type='email'
                value={email}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                  setEmailErr(null);
                }}
                className={`w-full h-12.5 m-0 transition-colors ${
                  emailErr ? 'field-error' : 'field'
                }`}
                placeholder='Enter your work email address'
              />

              {emailErr ? (
                <div className='px-2 text-red-006 text-3xs h-3 whitespace-nowrap'>
                  {emailErr}
                </div>
              ) : null}
            </div>

            <div className='w-full flex flex-col justify-center gap-3 pt-2.5'>
              <button
                type='submit'
                className='btn-delete rounded w-full h-15 flex items-center justify-center font-bold'
              >
                👉 See Instant Quote
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className='flex flex-col gap-4'>
        <div className='text-sms text-icon-gray italic'>
          Trusted by 90,000+ customers in 100+ countries
        </div>
        <div className='flex items-center justify-between'>
          <img
            src={getStaticAssetPath('images/onboarding/microsoft-v2.png')}
            alt='microsoft'
            className='w-15'
          />
          <img
            src={getStaticAssetPath('images/onboarding/stripe-v2.png')}
            alt='stripe'
            className='w-10.5'
          />
          <img
            src={getStaticAssetPath('images/onboarding/spotify-v2.png')}
            alt='spotify'
            className='w-12.5'
          />
          <img
            src={getStaticAssetPath('images/onboarding/meta-v2.png')}
            alt='meta'
            className='w-12.5'
          />
          <img
            src={getStaticAssetPath('images/onboarding/netflix-v3.png')}
            alt='netflix'
            className='w-11'
          />
        </div>
      </div>
    </div>
  );
}

export function GamePackDetailsSideAction(props: { pack: GamePack }) {
  const { pack } = props;

  return <GetInstantQuote pack={pack} />;
}
